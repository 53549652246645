<template>
  <div>
    <b-sidebar
      id="pl_sidebar"
      right
      shadow
    >
      <template #header="{ hide }">
        <div class="d-flex justify-content-center w-100">
          <div class="header">Прайс-лист</div>
          <div
            style="display: flex"
            class="ml-auto"
          >
            <img
              class="close-sidebar cursor"
              src="/img/integrations/icon-sidebar-close.svg"
              alt=""
              @click="hide"
            />
          </div>
        </div>
      </template>
      <div class="sidebar-block token">
        <div class="text mb-1 mt-2">Наименование</div>
        <b-input
          disabled
          :value="mapping[current_pl?.type]"
        />
        <div class="auto-calc d-flex align-items-center mt-4 pb-3 pt-4">
          <e-switch
            style="transform: scale(1.2)"
            class="mr-2"
            :value="current_pl.auto ? 'all' : 'off'"
            @input="(val) => (val === 'all' ? (current_pl.auto = true) : (current_pl.auto = false))"
          />
          Автоматический расчёт
        </div>
        <e-select
          selected_type="text"
          :value="
            current_pl.relation?.parent_price_list?.id ? [{ id: current_pl.relation?.parent_price_list?.id }] : []
          "
          :disabled="!current_pl.auto"
          :options="price_list_all"
          label="Прайс-лист"
          @input="updatePL"
        />
        <div class="text mb-1 mt-3">Процент изменения, %</div>
        <b-input
          v-model="current_pl.relation.price_percent"
          :disabled="!current_pl.relation?.parent_price_list?.id || !current_pl.auto"
        />
        <e-select
          class="mt-3"
          :options="round"
          selected_type="text"
          :value="current_pl.relation?.round_to ? [{ id: current_pl.relation?.round_to }] : []"
          :disabled="!current_pl.relation?.parent_price_list?.id || !current_pl.auto"
          label="Способ округления"
          @input="updatePLRound"
        />
      </div>
      <template #footer="{ hide }">
        <div class="b-action-buttons">
          <b-button
            variant="primary"
            class="cursor"
            @click="savePl"
          >
            {{ 'Сохранить' }}
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-3"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <div class="card border storage-locations">
      <div class="card-title">
        Прайс-листы
        <b-badge
          variant="primary"
          class="ml-2"
        >
          {{ pl_count }}
        </b-badge>
      </div>
      <div class="desc">Место, в котором система хранит цены магазина</div>
      <div
        v-dragscroll
        class="items"
      >
        <div class="items-list">
          <template v-if="isStorageLoading">
            <!-- <b-spinner variant="primary" label="Загрузка..." /> -->
          </template>
          <template>
            <b-button
              v-for="(item, index) in price_list"
              :key="index"
              variant="primary"
              class="storage-item"
              :disabled="item === 'purchase'"
              @click="openSideBar(item)"
            >
              <div class="storage-name">{{ mapping[item.type] }}</div>
              <div class="storage-location">
                {{ mapping[item.type] }}
              </div>
            </b-button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { BranchModel } from '@/models/branch.model'
  import ESwitch from '@/views/settings/components/access_rights/ESwitch.vue'

  export default {
    name: 'PriceList',
    components: { ESwitch },
    props: {
      branch: {
        type: BranchModel,
        default: () => {}
      }
    },
    apollo: {
      PriceLists: {
        query: require('../gql/PriceLists.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.branch.id
          }
        },
        result({ data }) {
          this.pl_count = data.PriceLists?.length
          this.price_list = data.PriceLists?.sort((el) => (el.type > el.type ? 1 : -1)) || []
        },
        skip() {
          return !this.branch.id
        }
      },
      Branches: {
        query: require('../gql/Branches.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.price_list_all = data.Branches.filter((el) => el.id !== this.branch.id).flatMap((br) =>
            br.price_list?.map((pl) => {
              return { id: pl.id, name: br.name + ': ' + this.mapping[pl.type] }
            })
          )
        },
        skip() {
          return !this.branch.id
        }
      }
    },
    data: function () {
      return {
        isStorageLoading: true,
        current_pl: {
          type: '',
          id: '',
          auto: false,
          relation: {
            price_percent: 0,
            round_to: 'none'
          }
        },
        price_list_all: [],
        price_list: [],
        round: [
          {
            name: 'До рубля',
            id: 'rubles'
          },
          {
            name: 'До 50 коп.',
            id: 'half'
          },
          {
            name: 'Не округлять',
            id: 'none'
          }
        ],
        visible: false,
        pl_count: 0,
        mapping: {
          retail: 'Розничный',
          purchase: 'Закупочный'
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    watch: {
      branch(newVal) {
        if (!newVal) return false

        this.storage_list = newVal.storage ?? []
        this.isStorageLoading = false
      }
    },

    methods: {
      openSideBar(input) {
        if (input.type === 'retail') this.$root.$emit('bv::toggle::collapse', 'pl_sidebar')
        this.current_pl = input
        if (!this.current_pl.relation) {
          this.current_pl.relation = {
            price_percent: 0,
            round_to: 'none',
            parent_price_list: {
              id: null
            }
          }
        }
      },
      updatePL(data) {
        if (this.current_pl.relation.parent_price_list.id !== data[0].id) {
          this.$bvModal
            .msgBoxConfirm('Система автоматически присвоит цены из прайс-листа: "' + data[0].name + '"', {
              title: 'Вы действительно хотите изменить цены в прайс-листе: "' + this.branch.name + '  Розничный"',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'primary',
              cancelVariant: 'outline-primary',
              okTitle: 'Продолжить',
              footerClass: 'flex-row-reverse p-3',
              cancelTitle: 'Отмена',
              centered: true
            })
            .then(async (value) => {
              if (value) {
                this.current_pl.relation.parent_price_list.id = data[0].id
              } else {
                this.current_pl.relation.parent_price_list = JSON.parse(
                  JSON.stringify(this.current_pl.relation.parent_price_list)
                )
              }
            })
        }
      },
      updatePLRound(data) {
        this.current_pl.relation.round_to = data[0].id
      },
      async savePl() {
        await this.$apollo.mutate({
          mutation: require('../gql/CreateOrUpdatePLRelation.graphql'),
          variables: {
            input: {
              parent_price_list: this.current_pl?.relation?.parent_price_list?.id,
              price_percent: +this.current_pl.relation?.price_percent,
              round_to: this.current_pl?.relation?.round_to,
              price_list: this.current_pl?.id,
              auto: this.current_pl.auto
            }
          }
        })
        this.$root.$emit('bv::toggle::collapse', 'pl_sidebar')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .auto-calc {
    border-top: 1px #e1e1e1 solid;
    color: var(--text-primary-gray-700, #313131);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
  }

  .card-title::v-deep .badge {
    font-size: 13px;
  }

  .desc {
    color: #888;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 22px;
  }

  .items {
    overflow-x: auto;
    padding-bottom: 10px;
  }

  .items-list {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 2px 0;
  }

  .storage-item {
    box-sizing: border-box;
    border: 1px solid #99daff;
    border-radius: 4px;
    background: #ffff;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    text-align: left;
    white-space: nowrap;
    flex-shrink: 0;

    &:active {
      background: #eff8fd !important;
      color: #0066ff !important;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    }

    &.not-collapsed {
      box-shadow: 0px 0px 0px 2px #b9d6ff !important;
    }
  }

  .storage-name {
    font-size: 16px;
    margin-bottom: 6px;
    font-family: Gilroy-Medium;
    color: var(--text-primary-gray-700, #313131);
  }

  .storage-location {
    color: var(--text-primary-gray-700, #313131);
    font-size: 12px;
  }

  .remove-btn {
    color: #888;
  }

  .save-btn {
    padding: 13px;
    font-size: 16px;
    margin-right: 9px;
  }

  .btn-primary:not(:disabled):not(.disabled):active {
    color: #0066ff;
  }

  .b-action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;

    button {
      height: 42px;
      font-family: 'Gilroy-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      &:nth-child(2) {
        margin-left: 12px;
        margin-right: 34px;
      }
    }
  }

  ::v-deep() {
    #pl_sidebar {
      width: 680px !important;

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;

        .header {
          color: var(--text-primary-gray-700, #313131);
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          padding: 22px;

          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }

          a {
            border-bottom: 1px solid #0066ff;
            font-size: 16px;
          }
        }

        .info {
          &__detail {
            a {
              color: #313131;
              font-weight: 600;
              border-bottom: 1px solid #313131;
            }
          }
        }

        .token {
          form {
            margin-top: 7px;

            input {
              height: 42px !important;
              border: 1px solid #bcbcbc;
              border-radius: 2px;
              font-size: 16px;
              color: #313131;
            }
          }
        }

        .b-select {
          border: 1px solid #e1e1e1;
          border-radius: 4px;

          &__title {
            border-bottom: 1px solid #e1e1e1;
            display: flex;

            .item {
              width: 50%;
              padding: 8px 0;
              text-align: center;
            }
          }

          &__item {
            display: flex;
            padding: 16px;
            justify-content: space-between;

            input,
            .ivu-select {
              width: 282px;
            }
          }
        }
      }
    }
  }
</style>
