var render = function render(){
  var _vm$form$new_storage_;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "wrapper__inner-widthlimit"
  }, [_c('div', {
    staticClass: "form-company pos-r"
  }, [_c('div', {
    staticClass: "btns-actions right"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.submitHandler
    }
  }, [_vm._v(" Сохранить ")])], 1), _c('div', {
    class: `loading-spinner pos-a ${!_vm.updating && _vm.is_loading ? 'active' : ''}`
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Загрузка..."
    }
  })], 1), _c('div', {
    staticClass: "card border"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_vm._v("Основные данные")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Компания")]), _c('p', [_vm._v(_vm._s(_vm.user.company.name))])]), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Наименование магазина")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "state": _vm.validation,
      "placeholder": "Введите название",
      "required": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("КПП")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "state": _vm.validation,
      "placeholder": "Введите КПП",
      "required": ""
    },
    model: {
      value: _vm.form.kpp,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kpp", $$v);
      },
      expression: "form.kpp"
    }
  })], 1)])]), _c('div', {
    staticClass: "card border"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_vm._v("Адрес")]), _c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Страна")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "",
      "required": ""
    },
    model: {
      value: _vm.form.address.country,
      callback: function ($$v) {
        _vm.$set(_vm.form.address, "country", $$v);
      },
      expression: "form.address.country"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Город")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "",
      "required": ""
    },
    model: {
      value: _vm.form.address.city,
      callback: function ($$v) {
        _vm.$set(_vm.form.address, "city", $$v);
      },
      expression: "form.address.city"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Улица")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "",
      "required": ""
    },
    model: {
      value: _vm.form.address.street,
      callback: function ($$v) {
        _vm.$set(_vm.form.address, "street", $$v);
      },
      expression: "form.address.street"
    }
  })], 1)]), _c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Дом")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "",
      "required": ""
    },
    model: {
      value: _vm.form.address.house,
      callback: function ($$v) {
        _vm.$set(_vm.form.address, "house", $$v);
      },
      expression: "form.address.house"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Квартира/Офис")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "",
      "required": ""
    },
    model: {
      value: _vm.form.address.apartment,
      callback: function ($$v) {
        _vm.$set(_vm.form.address, "apartment", $$v);
      },
      expression: "form.address.apartment"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Почтовый индекс")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "",
      "required": ""
    },
    model: {
      value: _vm.form.address.post_index,
      callback: function ($$v) {
        _vm.$set(_vm.form.address, "post_index", $$v);
      },
      expression: "form.address.post_index"
    }
  })], 1)])]), _c('storage-locations', {
    attrs: {
      "branch": _vm.form,
      "storage_count": _vm.storage_count + (((_vm$form$new_storage_ = _vm.form.new_storage_names) === null || _vm$form$new_storage_ === void 0 ? void 0 : _vm$form$new_storage_.length) || 0)
    },
    on: {
      "add-new-storage": _vm.addStorage
    }
  }), _c('price-list', {
    attrs: {
      "branch": _vm.form
    },
    on: {
      "add-new-storage": _vm.addStorage
    }
  }), _c('legal-entities', {
    attrs: {
      "branch": _vm.form,
      "entities_count": _vm.entities_count
    },
    on: {
      "update": _vm.update
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }