<template>
  <section>
    <!-- <breadcrumbs /> -->
    <div class="wrapper__inner-widthlimit">
      <div class="form-company pos-r">
        <div class="btns-actions right">
          <b-button
            variant="primary"
            @click="submitHandler"
          >
            Сохранить
          </b-button>
        </div>

        <div :class="`loading-spinner pos-a ${!updating && is_loading ? 'active' : ''}`">
          <b-spinner
            variant="primary"
            label="Загрузка..."
          />
        </div>

        <div class="card border">
          <div class="card-title">Основные данные</div>
          <div class="row">
            <div class="col-4">
              <label>Компания</label>
              <p>{{ user.company.name }}</p>
            </div>
            <div class="col-4">
              <label>Наименование магазина</label>
              <b-form-input
                v-model="form.name"
                type="text"
                :state="validation"
                placeholder="Введите название"
                required
              />
            </div>
            <div class="col-4">
              <label>КПП</label>
              <b-form-input
                v-model="form.kpp"
                type="text"
                :state="validation"
                placeholder="Введите КПП"
                required
              />
            </div>
          </div>
        </div>
        <div class="card border">
          <div class="card-title">Адрес</div>
          <div class="row mb-4">
            <div class="col-4">
              <label>Страна</label>
              <b-form-input
                v-model="form.address.country"
                type="text"
                placeholder=""
                required
              />
            </div>
            <div class="col-4">
              <label>Город</label>
              <b-form-input
                v-model="form.address.city"
                type="text"
                placeholder=""
                required
              />
            </div>
            <div class="col-4">
              <label>Улица</label>
              <b-form-input
                v-model="form.address.street"
                type="text"
                placeholder=""
                required
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-4">
              <label>Дом</label>
              <b-form-input
                v-model="form.address.house"
                type="text"
                placeholder=""
                required
              />
            </div>
            <div class="col-4">
              <label>Квартира/Офис</label>
              <b-form-input
                v-model="form.address.apartment"
                type="text"
                placeholder=""
                required
              />
            </div>
            <div class="col-4">
              <label>Почтовый индекс</label>
              <b-form-input
                v-model="form.address.post_index"
                type="text"
                placeholder=""
                required
              />
            </div>
          </div>
        </div>
        <storage-locations
          :branch="form"
          :storage_count="storage_count + (form.new_storage_names?.length || 0)"
          @add-new-storage="addStorage"
        />
        <price-list
          :branch="form"
          @add-new-storage="addStorage"
        />
        <legal-entities
          :branch="form"
          :entities_count="entities_count"
          @update="update"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import StorageLocations from './StorageLocations.vue'
  import LegalEntities from './LegalEntities.vue'

  import { mapGetters, mapActions } from 'vuex'
  import { BranchModel } from '@/models/branch.model'
  import { AddressModel } from '@/models/address.model'
  import PriceList from '@/views/settings/components/PriceList.vue'

  export default {
    apollo: {
      Branches: {
        query: require('../../core/header/gql/getBranches.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.setBranchList(data.Branches)
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      },
      Branch: {
        query: require('../gql/currentBranch.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.id
          }
        },
        result({ data }) {
          this.updating = false
          if (this.$route.meta.edit) this.form = new BranchModel(data.Branch)
          if (data?.Branch) {
            if (!data.Branch.address) {
              this.form.address = new AddressModel({
                country: '',
                city: '',
                street: '',
                house: '',
                apartment: '',
                post_index: ''
              })
            }
          }

          this.storage_count = this.form.storage.length
          this.entities_count = this.form.entities.length
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        },
        skip() {
          return !this.id
        },
        watchLoading(isLoading) {
          this.is_loading = isLoading
        }
      }
    },

    components: {
      PriceList,
      StorageLocations,
      LegalEntities
    },

    data: function () {
      return {
        is_loading: false,
        is_edit: false,
        id: this.$route.params.id || '',
        storage_count: 0,
        entities_count: 0,
        form: {
          name: '',
          address: {
            country: '',
            city: '',
            street: '',
            house: '',
            apartment: '',
            post_index: ''
          },
          kpp: '',
          new_storage_names: []
        },
        updating: false,
        validation: undefined
      }
    },

    beforeMount() {
      this.setBreadcrumbs({ is_go_back: true })
    },
    mounted() {
      this.setBreadcrumbs({ is_go_back: true })
    },

    created() {
      if (this.$route.meta.edit) this.getCurrentBranch(this.$route.params.id)
    },

    addStorage(name) {
      console.log(name)
    },

    computed: {
      ...mapGetters({
        user: 'core/getUserInfo'
      })
    },

    methods: {
      ...mapActions({
        setBranchList: 'settings/setBranchList',
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      is_valid() {
        if (this.form.name.length > 1) {
          this.validation = true
          return true
        }

        this.validation = false
        return false
      },
      update() {
        this.updating = true
        this.$apollo.queries.Branch.refetch()
      },

      addStorage(strName) {
        this.form.new_storage_names.push(strName)
      },

      submitHandler() {
        const is_valid = this.is_valid()
        if (!is_valid) return false

        if (!this.$route.meta.edit) return this.createBranch()

        this.updateBranch()
      },

      async createBranch() {
        await this.$apollo.mutate({
          mutation: require('../gql/CreateBranch.gql'),
          variables: {
            input: this.form
          }
        })
        await this.$apollo.queries.Branches.refetch()
        this.$router.back()
      },

      async updateBranch() {
        await this.$apollo.mutate({
          mutation: require('../gql/UpdateBranch.gql'),
          variables: {
            input: {
              id: this.form.id,
              name: this.form.name,
              kpp: this.form.kpp,
              address: this.form.address
            }
          }
        })
        await this.$apollo.queries.Branches.refetch()
        this.$router.back()
      },

      getCurrentBranch(id) {
        this.is_edit = true
        this.id = id
      }
    }
  }
</script>
