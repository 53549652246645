<template>
  <div>
    <div class="card border storage-locations">
      <div class="card-title">
        Места хранения
        <b-badge
          variant="primary"
          class="ml-2"
        >
          {{ storage_count }}
        </b-badge>
      </div>
      <div class="desc">
        Место хранения - это куда товар принимают и откуда его продают, т.е место где мы видим физический остаток
      </div>
      <div
        v-dragscroll
        class="items"
      >
        <div class="items-list">
          <button
            class="btn add-btn"
            @click="createNewStorage"
          >
            <img
              src="/img/icons/plus_blue.svg"
              alt=""
              width="12"
              height="12"
              class="mr"
            />
            Добавить место хранения
          </button>
          <template v-if="isStorageLoading">
            <!-- <b-spinner variant="primary" label="Загрузка..." /> -->
          </template>
          <template>
            <b-button
              v-for="(item, index) in storage_list"
              :key="index"
              variant="primary"
              class="storage-item"
              @click="set_current_storage(item)"
            >
              <div class="storage-name">{{ item.name }}</div>
              <div class="storage-location">
                {{ branch.address.city }}, {{ branch.address.street }},
                {{ branch.address.house }}
              </div>
            </b-button>
          </template>
        </div>
      </div>
      <b-collapse
        id="name-form"
        v-model="visible"
      >
        <b-row class="mt-4">
          <b-col width="6">
            <label>Наименование</label>
            <b-form-input
              v-model="current_storage.name"
              type="text"
              name="storage_name"
              placeholder=""
              required
            />
          </b-col>
          <b-col
            v-if="current_storage.id"
            width="6"
            class="align-self-end text-right"
          >
            <button
              class="btn remove-btn"
              @click.prevent="remove_storage"
            >
              Удалить место хранения
              <img
                src="/img/icons/trash.svg"
                alt=""
                class="ml"
              />
            </button>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-button
              variant="primary"
              class="save-btn"
              @click.prevent="addStorage"
            >
              Готово
            </b-button>
            <b-button
              variant="outline-primary"
              @click.prevent="visible = false"
            >
              Отмена
            </b-button>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import * as R from 'ramda'

  export default {
    props: {
      branch: {
        type: [Array, Object],
        default: () => {}
      },

      storage_count: {
        type: Number,
        default: 0
      },

      address: {
        type: [Array, Object],
        default: () => {}
      }
    },

    data: function () {
      return {
        isStorageLoading: true,
        current_storage: {
          name: '',
          id: ''
        },
        storage_list: this.branch.storage ?? [],
        visible: false
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    watch: {
      branch(newVal) {
        if (!newVal) return false

        this.storage_list = newVal.storage ?? []
        this.isStorageLoading = false
      }
    },

    methods: {
      addStorage() {
        if (!this.branch?.id) {
          this.$emit('add-new-storage', this.current_storage.name)
          this.storage_list.push({
            name: this.current_storage.name
          })
          this.toggleAddStorageBlock()

          return
        }
        if (!this.current_storage.id) {
          this.$apollo
            .mutate({
              mutation: require('../gql/createStorage.gql'),
              variables: {
                name: this.current_storage.name,
                branch: this.branch.id,
                address: this.branch.address
              }
            })
            .then(({ data }) => {
              const result = R.pathOr('', ['CreateStorage'], data)
              this.storage_list.push(result)
              this.toggleAddStorageBlock()
            })
            .catch((error) => {
              this.$noty.show(error.graphQLErrors[0].message)
            })
        } else {
          this.$apollo
            .mutate({
              mutation: require('../gql/UpdateStorage.gql'),
              variables: {
                name: this.current_storage.name,
                id: this.current_storage.id,
                branch: this.branch.id,
                address: this.branch.address
              }
            })
            .then(() => {
              this.visible = false
            })
            .catch((error) => {
              this.$noty.show(error.graphQLErrors[0].message)
            })
        }
      },

      set_current_storage(item) {
        if (!this.visible) {
          this.visible = true
          this.current_storage = item
        } else if (this.visible && item?.id === this.current_storage?.id) {
          this.current_storage = {}
          this.visible = false
        } else {
          this.current_storage = item
        }
      },

      remove_storage() {
        this.$apollo
          .mutate({
            mutation: require('../gql/removeStorage.gql'),
            variables: {
              id: this.current_storage.id
            }
          })
          .then(({ data }) => {
            const status = R.pathOr('', ['RemoveStorage', 'status'], data)
            if (status) this.removeItem(this.current_storage.id)
          })
          .catch((error) => {
            this.$noty.show(error.graphQLErrors[0]?.extensions?.ru)
          })
      },

      removeItem(id) {
        this.storage_list.forEach((storage, index) => {
          if (id === storage.id) {
            this.storage_list.splice(index, 1)
            this.toggleAddStorageBlock()
          }
        })
      },

      toggleAddStorageBlock() {
        this.current_storage.name = ''
        this.current_storage.id = ''
        this.$root.$emit('bv::toggle::collapse', 'name-form')
      },
      createNewStorage() {
        this.current_storage = {
          name: '',
          id: ''
        }
        this.visible = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .card-title::v-deep .badge {
    font-size: 13px;
  }
  .desc {
    color: #888;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 22px;
  }
  .items {
    overflow-x: auto;
    padding-bottom: 10px;
  }
  .items-list {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 2px 0;
  }
  .add-btn {
    padding: 20px 15px;
    height: auto;
    border: 1px dashed #99daff;
    color: #00a3ff;
    white-space: nowrap;
    flex-shrink: 0;
    min-width: 223px;
    justify-content: center;
  }

  .storage-item {
    box-sizing: border-box;
    border: 1px solid #99daff;
    border-radius: 4px;
    background: #ffff;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    text-align: left;
    white-space: nowrap;
    flex-shrink: 0;
    &:active {
      background: #eff8fd !important;
      color: #0066ff !important;
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    }
    &.not-collapsed {
      box-shadow: 0px 0px 0px 2px #b9d6ff !important;
    }
  }
  .storage-name {
    font-size: 16px;
    margin-bottom: 6px;
    font-family: Gilroy-Medium;
    color: var(--text-primary-gray-700, #313131);
  }
  .storage-location {
    color: var(--text-primary-gray-700, #313131);
    font-size: 12px;
  }
  .remove-btn {
    color: #888;
  }
  .save-btn {
    padding: 13px;
    font-size: 16px;
    margin-right: 9px;
  }
  .btn-primary:not(:disabled):not(.disabled):active {
    color: #0066ff;
  }
</style>
