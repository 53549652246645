var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "card border storage-locations"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_vm._v(" Места хранения "), _c('b-badge', {
    staticClass: "ml-2",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.storage_count) + " ")])], 1), _c('div', {
    staticClass: "desc"
  }, [_vm._v(" Место хранения - это куда товар принимают и откуда его продают, т.е место где мы видим физический остаток ")]), _c('div', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    staticClass: "items"
  }, [_c('div', {
    staticClass: "items-list"
  }, [_c('button', {
    staticClass: "btn add-btn",
    on: {
      "click": _vm.createNewStorage
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/plus_blue.svg",
      "alt": "",
      "width": "12",
      "height": "12"
    }
  }), _vm._v(" Добавить место хранения ")]), _vm.isStorageLoading ? void 0 : _vm._e(), _vm._l(_vm.storage_list, function (item, index) {
    return _c('b-button', {
      key: index,
      staticClass: "storage-item",
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.set_current_storage(item);
        }
      }
    }, [_c('div', {
      staticClass: "storage-name"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "storage-location"
    }, [_vm._v(" " + _vm._s(_vm.branch.address.city) + ", " + _vm._s(_vm.branch.address.street) + ", " + _vm._s(_vm.branch.address.house) + " ")])]);
  })], 2)]), _c('b-collapse', {
    attrs: {
      "id": "name-form"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "width": "6"
    }
  }, [_c('label', [_vm._v("Наименование")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "name": "storage_name",
      "placeholder": "",
      "required": ""
    },
    model: {
      value: _vm.current_storage.name,
      callback: function ($$v) {
        _vm.$set(_vm.current_storage, "name", $$v);
      },
      expression: "current_storage.name"
    }
  })], 1), _vm.current_storage.id ? _c('b-col', {
    staticClass: "align-self-end text-right",
    attrs: {
      "width": "6"
    }
  }, [_c('button', {
    staticClass: "btn remove-btn",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.remove_storage.apply(null, arguments);
      }
    }
  }, [_vm._v(" Удалить место хранения "), _c('img', {
    staticClass: "ml",
    attrs: {
      "src": "/img/icons/trash.svg",
      "alt": ""
    }
  })])]) : _vm._e()], 1), _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', [_c('b-button', {
    staticClass: "save-btn",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addStorage.apply(null, arguments);
      }
    }
  }, [_vm._v(" Готово ")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.visible = false;
      }
    }
  }, [_vm._v(" Отмена ")])], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }