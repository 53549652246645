var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card border legal-entities"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_vm._v(" Юридические лица "), _c('b-badge', {
    staticClass: "ml-2",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.entities_count) + " ")])], 1), _c('div', {
    staticClass: "desc"
  }, [_vm._v(" Юрлицо - это организация которая указывается в различных документах товародвижения, чеках, ценниках и тд. ")]), _c('div', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    staticClass: "items"
  }, [_c('div', {
    staticClass: "items-list"
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeSheet,
      expression: "closeSheet"
    }]
  }, [_c('transition', {
    attrs: {
      "name": "entity-search-container"
    }
  }, [_vm.sheet ? _c('div', {
    staticClass: "entity-search"
  }, [_c('div', {
    staticClass: "input-box"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter,
      expression: "filter"
    }],
    staticClass: "entity-search-input",
    attrs: {
      "type": "text",
      "placeholder": "Поиск..."
    },
    domProps: {
      "value": _vm.filter
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.filter = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "entity-search-list"
  }, _vm._l(_vm.entity_list_filtered, function (entity) {
    return _c('div', {
      key: entity.id,
      staticClass: "entity-search-item"
    }, [_c('e-checkbox', {
      staticClass: "mr-3 entity-search-checkbox",
      attrs: {
        "checked": _vm.active(entity.id)
      },
      on: {
        "click": function ($event) {
          return _vm.entity_click(entity.id);
        }
      }
    }), _c('div', [_c('entity-info', {
      attrs: {
        "entity": entity
      }
    })], 1)], 1);
  }), 0), _c('div', {
    staticClass: "new-entity",
    on: {
      "click": _vm.createEntity
    }
  }, [_c('img', {
    staticClass: "ml-2 mr-2",
    attrs: {
      "src": "/img/icons/plus_dark.svg",
      "alt": "add"
    }
  }), _vm._v(" Создать новое юр. лицо ")])]) : _vm._e()]), _c('button', {
    staticClass: "btn add-btn",
    class: _vm.visible ? null : 'collapsed',
    attrs: {
      "aria-expanded": _vm.visible ? 'true' : 'false',
      "aria-controls": "collapse-2"
    },
    on: {
      "click": function ($event) {
        _vm.sheet = !_vm.sheet;
      }
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/plus_blue.svg",
      "alt": "",
      "width": "12",
      "height": "12"
    }
  }), _vm._v(" Привязать юрлицо ")])], 1), _vm._l(_vm.branch.entities, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "legal-entitie-item",
      class: {
        collapsed: _vm.visible
      },
      on: {
        "click": function ($event) {
          return _vm.openEntity(item.id);
        }
      }
    }, [_c('div', {
      staticClass: "legal-entitie-name"
    }, [item.main ? _c('svg', {
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "16",
        "height": "17",
        "viewBox": "0 0 16 17",
        "fill": "none"
      }
    }, [_c('path', {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        "d": "M11.7077 14.4999C11.6024 14.4999 11.4964 14.4753 11.3991 14.4246L7.99975 12.6486L4.60108 14.4246C4.37575 14.5413 4.10375 14.5213 3.89975 14.3719C3.69442 14.2226 3.59242 13.9699 3.63575 13.7199L4.28308 9.96795L1.53642 7.31128C1.35375 7.13461 1.28775 6.86928 1.36575 6.62661C1.44375 6.38528 1.65242 6.20861 1.90442 6.17261L5.70442 5.62061L7.40308 2.20328C7.62842 1.74995 8.37175 1.74995 8.59708 2.20328L10.2957 5.62061L14.0957 6.17261C14.3477 6.20861 14.5564 6.38528 14.6344 6.62661C14.7124 6.86928 14.6464 7.13461 14.4637 7.31128L11.7171 9.96795L12.3644 13.7199C12.4077 13.9699 12.3051 14.2226 12.1004 14.3719C11.9844 14.4573 11.8464 14.4999 11.7077 14.4999Z",
        "fill": "#00A3FF"
      }
    })]) : _vm._e(), _c('div', {
      staticClass: "name-text"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('img', {
      staticClass: "close-icon",
      attrs: {
        "src": "/img/icons/close-comparison.svg",
        "alt": ""
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.del(item.id);
        }
      }
    })]), _c('div', {
      staticClass: "legal-entitie-info"
    }, [_vm._v("ИНН: " + _vm._s(item.inn || '-') + "   КПП: " + _vm._s(item.kpp || '-'))])]);
  })], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }