<template>
  <div class="card border legal-entities">
    <div class="card-title">
      Юридические лица
      <b-badge
        variant="primary"
        class="ml-2"
      >
        {{ entities_count }}
      </b-badge>
    </div>
    <div class="desc">
      Юрлицо - это организация которая указывается в различных документах товародвижения, чеках, ценниках и тд.
    </div>
    <div
      v-dragscroll
      class="items"
    >
      <div class="items-list">
        <div v-click-outside="closeSheet">
          <transition name="entity-search-container">
            <div
              v-if="sheet"
              class="entity-search"
            >
              <div class="input-box">
                <input
                  v-model="filter"
                  type="text"
                  placeholder="Поиск..."
                  class="entity-search-input"
                />
              </div>
              <div class="entity-search-list">
                <div
                  v-for="entity in entity_list_filtered"
                  :key="entity.id"
                  class="entity-search-item"
                >
                  <e-checkbox
                    :checked="active(entity.id)"
                    class="mr-3 entity-search-checkbox"
                    @click="entity_click(entity.id)"
                  />
                  <div>
                    <entity-info :entity="entity"></entity-info>
                  </div>
                </div>
              </div>
              <div
                class="new-entity"
                @click="createEntity"
              >
                <img
                  src="/img/icons/plus_dark.svg"
                  alt="add"
                  class="ml-2 mr-2"
                />
                Создать новое юр. лицо
              </div>
            </div>
          </transition>
          <button
            :class="visible ? null : 'collapsed'"
            class="btn add-btn"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-2"
            @click="sheet = !sheet"
          >
            <img
              src="/img/icons/plus_blue.svg"
              alt=""
              width="12"
              height="12"
              class="mr"
            />
            Привязать юрлицо
          </button>
        </div>
        <div
          v-for="(item, index) in branch.entities"
          :key="index"
          class="legal-entitie-item"
          :class="{
            collapsed: visible
          }"
          @click="openEntity(item.id)"
        >
          <div class="legal-entitie-name">
            <svg
              v-if="item.main"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7077 14.4999C11.6024 14.4999 11.4964 14.4753 11.3991 14.4246L7.99975 12.6486L4.60108 14.4246C4.37575 14.5413 4.10375 14.5213 3.89975 14.3719C3.69442 14.2226 3.59242 13.9699 3.63575 13.7199L4.28308 9.96795L1.53642 7.31128C1.35375 7.13461 1.28775 6.86928 1.36575 6.62661C1.44375 6.38528 1.65242 6.20861 1.90442 6.17261L5.70442 5.62061L7.40308 2.20328C7.62842 1.74995 8.37175 1.74995 8.59708 2.20328L10.2957 5.62061L14.0957 6.17261C14.3477 6.20861 14.5564 6.38528 14.6344 6.62661C14.7124 6.86928 14.6464 7.13461 14.4637 7.31128L11.7171 9.96795L12.3644 13.7199C12.4077 13.9699 12.3051 14.2226 12.1004 14.3719C11.9844 14.4573 11.8464 14.4999 11.7077 14.4999Z"
                fill="#00A3FF"
              />
            </svg>
            <div class="name-text">
              {{ item.name }}
            </div>
            <img
              class="close-icon"
              src="/img/icons/close-comparison.svg"
              alt=""
              @click.stop="del(item.id)"
            />
          </div>
          <div class="legal-entitie-info">ИНН: {{ item.inn || '-' }} &nbsp; КПП: {{ item.kpp || '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as R from 'ramda'
  import { BranchModel } from '@/models/branch.model'
  import EntityInfo from "@/views/payment/components/EntityInfo.vue";

  export default {
    components: {EntityInfo},
    // components: { InfoBox },
    props: {
      entities_count: {
        type: Number,
        default: 0
      },
      branch: BranchModel
    },

    apollo: {
      TaxRates: {
        query: require('../gql/getTaxRates.gql'),
        result({ data }) {
          this.taxRateList = R.pathOr([], ['TaxRates'], data)
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Entities: {
        query: require('../gql/getEntities.graphql'),
        result({ data }) {
          this.entity_list = data.Entities
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data: () => ({
      taxRateList: [],
      entity_list: [],
      loadings: [],
      visible: false,
      sheet: false,
      inn_search_result: false,
      activeLegalEntityId: null,
      innState: null,
      nameState: null,
      ogrnState: null,
      countryState: null,
      cityState: null,
      indexState: null,
      isEdit: false,
      filter: ''
    }),

    // watch: {
    //   currentEntityData(newVal, oldVal) {
    //     if (newVal !== oldVal && this.is_edit) {
    //       this.entity = R.mergeLeft(
    //         this.currentEntityData.entity,
    //         this.entity
    //       )
    //     }
    //   }
    // },
    computed: {
      entity_list_filtered() {
        const filter = this.filter.toLowerCase()
        return this.entity_list.filter(
          (el) =>
            el.name?.toLowerCase().includes(filter) ||
            el.full_name?.toLowerCase().includes(filter) ||
            el.inn?.toLowerCase().includes(filter)
        )
      }
    },
    mounted() {},
    methods: {
      formatter_number(value) {
        return value.replace(/[^0-9]/g, '')
      },
      active(id) {
        return this.branch.entities.some((el) => el.id === id)
      },
      entity_click(id) {
        if (this.active(id)) this.del(id)
        else this.add(id)
      },
      createEntity() {
        this.$router.push({ name: 'company.entities.new' })
      },
      openEntity(id) {
        this.$router.push({ name: 'company.entities.edit', params: { id } })
      },
      closeSheet() {
        this.sheet = false
      },
      async add(id) {
        await this.$apollo.mutate({
          mutation: require('../gql/AddBkpp.graphql'),
          variables: {
            branch: this.branch.id,
            entity: id
          }
        })
        this.$emit('update')
      },
      async del(id) {
        this.$bvModal
          .msgBoxConfirm('Вы действительно хотите отвязать юр. лицо от магазина?', {
            title: 'Подтвердите действие',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            cancelVariant: 'outline-primary',
            okTitle: 'Продолжить',
            footerClass: 'flex-row-reverse p-3',
            cancelTitle: 'Отмена',
            centered: true
          })
          .then(async (value) => {
            if (value) {
              await this.$apollo.mutate({
                mutation: require('../gql/DelBkpp.graphql'),
                variables: {
                  branch: this.branch.id,
                  entity: id
                }
              })
              this.$emit('update')
            }
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* we will explain what these classes do next! */
  .entity-search-container-enter-active,
  .entity-search-container-leave-active {
    transition: opacity 0.5s ease;
  }

  .entity-search-container-enter,
  .entity-search-container-leave-to {
    opacity: 0;
  }

  .entity-search {
    display: flex;
    width: 340px;
    height: 400px;
    overflow: hidden;
    flex-direction: column;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    position: absolute;
    bottom: 100px;
    background: white;
    border-radius: 4px;

    .entity-search-list {
      overflow: auto;
      padding-bottom: 12px;
      flex: 1;
    }

    .new-entity {
      color: var(--text-primary-gray-700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      padding: 6px;
      background: var(--gray-white, #fff);
      box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    .input-box {
      display: flex;
      padding-top: 6px;
      padding-bottom: 6px;
      border-bottom: 1px #efefef solid;
    }

    .entity-search-input {
      border: none;
      flex: 1;
      padding-left: 16px;
      padding-right: 16px;
    }

    .entity-search-item {
      padding-left: 12px;
      display: flex;
      margin-top: 12px;

      .entity-search-checkbox {
        margin-top: 3px;
      }
    }
  }

  .card-title::v-deep .badge {
    font-size: 13px;
  }

  .is-invalid {
    border: 1px solid #e53835 !important;
    box-shadow: 0px 0px 0px 2px #ffcdd2 !important;
    border-radius: 2px !important;
    background-image: none;
  }

  .b-search-result {
    position: absolute;
    z-index: 10;
    top: 75px;
    left: 15px;
    max-width: 543px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .item {
      padding: 8px 14px;

      &-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #313131;
      }

      &-info {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        height: 24px;
        overflow: hidden;

        &.street {
          font-weight: 500;
          color: #313131;
          font-size: 14px;
        }

        span {
          margin-right: 12px;

          &.post_index {
            margin-right: 0;
            margin-left: 16px;
          }
        }
      }
    }
  }

  .desc {
    color: #888;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 22px;
  }

  .items {
    padding-bottom: 10px;
  }

  .items-list {
    display: flex;
    gap: 12px;
    padding: 2px 0;
    align-items: stretch;
    overflow: hidden;
    overflow-x: auto;
  }

  .add-btn {
    padding: 20px 15px;
    height: 60px;
    border: 1px dashed #b9d6ff;
    color: #00a3ff;
    white-space: nowrap;
    flex-shrink: 0;
    min-width: 223px;
    justify-content: center;
  }

  .legal-entitie-item {
    background: #ffffff;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    text-align: left;
    white-space: nowrap;
    flex-shrink: 0;
    border: 1px solid #99daff;
    width: 250px;
    transition: 200ms;

    path {
      fill: #ffffff;
    }

    &:active {
      background: #eff8fd !important;
    }

    &.active__btn {
      path {
        fill: #00a3ff;
      }

      border: 1px solid #99daff;
      background: darken(#eff8fd, 5%);

      .star {
        fill: #e53835;
      }
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    }

    .close-icon {
      display: none;
    }

    &:hover .close-icon {
      cursor: pointer;
      display: block;
    }

    &.not-collapsed {
      box-shadow: 0px 0px 0px 2px #b9d6ff !important;
    }
  }

  .legal-entitie-name {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    display: flex;
    width: 100%;

    .name-text {
      font-family: Gilroy-Medium;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      color: var(--text-primary-gray-700, #313131);
      font-size: 16px;
      font-style: normal;
      line-height: normal;
    }
  }

  .legal-entitie-info {
    font-size: 12px;
  }

  .remove-btn {
    color: #888;
  }

  .save-btn {
    display: block;
    height: auto;
    padding: 13px;
    font-size: 16px;
  }

  .form-section {
    legend {
      font-size: 18px;
      font-weight: 500;
      color: #bcbcbc;
      margin-bottom: 15px;
    }
  }

  .tax-rate {
    width: 154px;
  }

  .hide-checkbox {
    color: #888;

    &::v-deep .custom-control-label {
      &::before,
      &::after {
        top: 0;
      }
    }
  }
</style>
